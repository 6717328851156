import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

//VIIRS
import viirs_2017 from './viirs_2017';
import viirs_2018 from './viirs_2018';
import viirs_2019 from './viirs_2019';
import viirs_2020 from './viirs_2020';
import viirs_2021 from './viirs_2021';
import viirs_2022 from './viirs_2022';
import viirs_2023 from './viirs_2023';
import viirs_2024 from './viirs_2024';
import viirs_2025 from './viirs_2025';

const karagatanURL = process.env.GEOSERVER;
const attEOG = '<a href="https://eogdata.mines.edu/vbd/">Earth Observation Group, Payne Institute for Public Policy</a>';

const viirs =  new LayerGroup({
    title: '<img src="https://karagatanpatrol.org/symbols/viirs_bd.png" alt="" width="16" height="16">VIIRS Boat Detection',
    fold: 'open',
    layers: [
        viirs_2017,
        viirs_2018, 
        viirs_2019,
        viirs_2020,
        viirs_2021,
        viirs_2022,
        viirs_2023,
        viirs_2024,
        viirs_2025
        ]
    });

export default viirs;