/* eslint-disable indent */
//CSS and Style Import
import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import 'ol-popup/src/ol-popup.css';
import './assets/css/kp.css';


//Source Import
import Map from 'ol/Map';
import View from 'ol/View';
import {transform} from 'ol/proj';
import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import ImageLayer from 'ol/layer/Image';
import ImageStatic from 'ol/source/ImageStatic';
import povertyIncidenceLayers from './layers/povertyIncidence';
import closedSeason from './layers/closedSeason';
import baseMaps from './layers/baseMaps';
import managementRegimes from './layers/managementRegimes';
import lawEnforcement from './layers/lawEnforcement';
import viirs from './layers/viirs';
import coralBleaching from './layers/coralBleaching';
import oilSpill from './layers/oilSpill';
import atinAngKinse from './layers/atinAngKinse';
// import hb7853 from './layers/hb7853';

// import {WindLayer} from 'ol-wind';

import {
    Circle as CircleStyle,
    Fill,
    RegularShape,
    Stroke,
    Style,
    Text
  } from 'ol/style';
import {Draw, Modify, Snap} from 'ol/interaction';
import {LineString, Point} from 'ol/geom';
import {getArea, getLength} from 'ol/sphere';


//Controls
import MousePosition from 'ol/control/MousePosition.js';
import {Control, ZoomSlider} from 'ol/control';
import LayerSwitcher from 'ol-layerswitcher';
import {toStringHDMS} from 'ol/coordinate';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
// import gpsControl from './scripts/gpsControl';

(function(win, doc) {

const karagatanURL = process.env.GEOSERVER;

const showSegments = true;
const clearPrevious = false;
const source = new VectorSource();
const vector = new VectorLayer({
    source: source,
    style: function(feature) {
      return styleFunction(feature, showSegments);
    }
  });
//Query Layers
const wmsSource = new TileWMS({
  url: karagatanURL,
  params: {
    'LAYERS': ['karagatan:Municipal Waters', 'karagatan:Protected Areas', 'karagatan:DENR', 'karagatan:MARINA', 'karagatan:pnp_mg', 'karagatan:PCG', 'karagatan:bfar', 'karagatan:pnp'],
    'TILED': true,
    'VERSION': '1.3.0'
  },
  serverType: 'geoserver'

});
const view = new View({
    //National
    center: transform([125, 12], 'EPSG:4326', 'EPSG:3857'),
    zoom: 6

    // Visayan Sea
    // center: transform([121.50, 12.250], 'EPSG:4326', 'EPSG:3857'),
    // zoom: 7.5

    // MT Terranova Oil Spill
    // center: transform([120.75, 14.56], 'EPSG:4326', 'EPSG:3857'),
    // zoom: 10

});

//Map Layers
const map = new Map({
    target: 'map-container',
    layers: [   baseMaps, 
                managementRegimes, 
                lawEnforcement, 
                coralBleaching, 
                oilSpill,
                closedSeason,
                // povertyIncidenceLayers, 
                viirs, 
                atinAngKinse,
                // hb7853,
                vector
    ],
    view: view
});

//Layer InformationQuery 

map.on(['click'], function(evt) {
    //Check if the layer is visible

    // if (!wmsLayer3.getVisible()) return;

    //Proceed with the query if the layer is visible
    document.getElementById('info').innerHTML = '';

    const viewResolution = 10;
    /** @type {number} *//** (view.getResolution())*/
    const url = wmsSource.getFeatureInfoUrl(
      evt.coordinate, viewResolution, 'EPSG:3857',
      {
        'INFO_FORMAT': 'text/html',
        'QUERY_LAYERS': ['karagatan:Municipal Waters','karagatan:Protected Areas', 'karagatan:DENR', 'karagatan:MARINA', 'karagatan:pnp_mg', 'karagatan:PCG', 'karagatan:bfar', 'karagatan:pnp']
    });

    if (url) {
      fetch(url)
        .then(function(response) {
          return response.text();
        })
        .then(function(html) {
          document.getElementById('info').innerHTML = html;
        });
    }
  });
////

//Controls
//Layer Swticher
const layerSwitcher = new LayerSwitcher({
    activationMode: 'click',
    startActive: true,
    tipLabel: 'Layers', // Tooltip label
    collapseLabel: '\u00BB', // Collapse icon
    expandLabel: '\u00AB', // Expand icon
    groupSelectStyle: 'children'
});
map.addControl(layerSwitcher);
layerSwitcher.renderPanel()

//Zoom slider
const zoomslider = new ZoomSlider();
map.addControl(zoomslider);


//Mouse Position Control
const mousePosition = new MousePosition({
    coordinateFormat: function(coord) {
        return toStringHDMS(coord);
    },
    projection: 'EPSG:4326',
    target: document.getElementById('custom-mouse-position')
});
map.addControl(mousePosition);


/** Measure Tool */

const style = new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)'
    }),
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.5)',
      lineDash: [10, 10],
      width: 2
    }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      })
    })
  });

const labelStyle = new Style({
    text: new Text({
      font: '14px Calibri,sans-serif',
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)'
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      padding: [3, 3, 3, 3],
      textBaseline: 'bottom',
      offsetY: -15
    }),
    image: new RegularShape({
      radius: 8,
      points: 3,
      angle: Math.PI,
      displacement: [0, 10],
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.7)'
      })
    })
  });

const tipStyle = new Style({
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)'
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)'
      }),
      padding: [2, 2, 2, 2],
      textAlign: 'left',
      offsetX: 15
    })
  });

const modifyStyle = new Style({
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)'
      })
    }),
    text: new Text({
      text: 'Drag to modify',
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)'
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      padding: [2, 2, 2, 2],
      textAlign: 'left',
      offsetX: 15
    })
  });

const segmentStyle = new Style({
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)'
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)'
      }),
      padding: [2, 2, 2, 2],
      textBaseline: 'bottom',
      offsetY: -12
    }),
    image: new RegularShape({
      radius: 6,
      points: 3,
      angle: Math.PI,
      displacement: [0, 8],
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)'
      })
    })
  });

const segmentStyles = [segmentStyle];

const formatLength = function(line) {
    const length = getLength(line);
    let output;
    if (length > 100) {
      output = Math.round((length / 1000) * 100) / 100 + ' km';
    } else {
      output = Math.round(length * 100) / 100 + ' m';
    }
    return output;
  };

const formatArea = function(polygon) {
    const area = getArea(polygon);
    let output;
    if (area > 10000) {
      output = Math.round((area / 1000000) * 100) / 100 + ' km\xB2';
    } else {
      output = Math.round(area * 100) / 100 + ' m\xB2';
    }
    return output;
  };


const modify = new Modify({source: source, style: modifyStyle});

let tipPoint;

function styleFunction(feature, segments, drawType, tip) {
    const styles = [style];
    const geometry = feature.getGeometry();
    const type = geometry.getType();
    let point, label, line;
    if (!drawType || drawType === type) {
      if (type === 'Polygon') {
        point = geometry.getInteriorPoint();
        label = formatArea(geometry);
        line = new LineString(geometry.getCoordinates()[0]);
      } else if (type === 'LineString') {
        point = new Point(geometry.getLastCoordinate());
        label = formatLength(geometry);
        line = geometry;
      }
    }
    if (segments && line) {
      let count = 0;
      line.forEachSegment(function(a, b) {
        const segment = new LineString([a, b]);
        const label = formatLength(segment);
        if (segmentStyles.length - 1 < count) {
          segmentStyles.push(segmentStyle.clone());
        }
        const segmentPoint = new Point(segment.getCoordinateAt(0.5));
        segmentStyles[count].setGeometry(segmentPoint);
        segmentStyles[count].getText().setText(label);
        styles.push(segmentStyles[count]);
        count++;
      });
    }
    if (label) {
      labelStyle.setGeometry(point);
      labelStyle.getText().setText(label);
      styles.push(labelStyle);
    }
    if (
      tip &&
      type === 'Point' &&
      !modify.getOverlay().getSource().getFeatures().length
    ) {
      tipPoint = geometry;
      tipStyle.getText().setText(tip);
      styles.push(tipStyle);
    }
    return styles;
  }

map.addInteraction(modify);

let draw; // global so we can remove it later

const snapInteraction = new Snap({
    source: vector.getSource()
  });

function addInteraction(geomType) {
    const drawType = geomType;
    const activeTip =
      'Click to continue drawing the ' +
      (drawType === 'Polygon' ? 'polygon' : 'line');
    const idleTip = 'Click to start measuring';
    let tip = idleTip;
    draw = new Draw({
      source: source,
      type: drawType,
      style: function(feature) {
        return styleFunction(feature, showSegments, drawType, tip);
      }
    });
    draw.on('drawstart', function() {
        if (clearPrevious) {
          source.clear();
        }
        modify.setActive(false);
        tip = activeTip;
      });
    draw.on('drawend', function() {
      modifyStyle.setGeometry(tipPoint);
      modify.setActive(true);
      map.once('pointermove', function() {
        modifyStyle.setGeometry();
      });
      tip = idleTip;
    });
    modify.setActive(true);
    map.addInteraction(snapInteraction);
    map.addInteraction(draw);
  }


/** Measure Control */

//distance button
const measure_dist_btn = document.createElement('div');
measure_dist_btn.className = 'measure-dist ol-unselectable ol-control ';
measure_dist_btn.innerHTML = '<button title="Distance Measurement"><img src="/assets/image/measure_dist.png"/></button>';
measure_dist_btn.addEventListener('click', function() {
    map.removeInteraction(draw);
    const geomType = 'LineString';
    addInteraction(geomType);
});
map.addControl(new Control({
    element: measure_dist_btn
}));

//area button
const measure_area_btn = document.createElement('div');
measure_area_btn.className = 'measure-area ol-unselectable ol-control ';
measure_area_btn.innerHTML = '<button title="Calculate Area"><img src="/assets/image/measure_area.png" /></button>';
measure_area_btn.addEventListener('click', function() {
    map.removeInteraction(draw);
    const geomType = 'Polygon';
    addInteraction(geomType);
});
map.addControl(new Control({
    element: measure_area_btn
}));


//clear drawing button
const clear_btn = document.createElement('div');
clear_btn.className = 'clear ol-unselectable ol-control ';
clear_btn.innerHTML = '<button title="Clear Drawing" ><img src="/assets/image/clear.png" /></button>';
clear_btn.addEventListener('click', function() {
    source.clear();
    map.removeInteraction(draw);
    addInteraction;
});
map.addControl(new Control({
    element: clear_btn
}));


//analytics link
const analytics_btn = document.createElement('div');
analytics_btn.className = 'analytics ol-unselectable ol-control ';
analytics_btn.innerHTML = '<button title="Data Analytics" ><img src="/assets/image/analytics.png" /></button>';
analytics_btn.addEventListener('click', function() {
    const url = 'http://karagatanpatrol.org/analytics/';
    window.open(url, '_blank');

});
map.addControl(new Control({
    element: analytics_btn
}));

})(window, document);
