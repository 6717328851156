import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const karagatanURL = process.env.GEOSERVER;
const attEOG = '<a href="https://eogdata.mines.edu/vbd/">Earth Observation Group, Payne Institute for Public Policy</a>';

const viirs_2025 =  new LayerGroup({
   title: '2025',
   fold: 'open',
   layers: [
///////////monthly
    new LayerTile({
    title: '2025 January',
    visible: false,
    source: new TileWMS({
        url: karagatanURL,
        params: {
            'LAYERS': 'karagatan:2025_january',
            'VERSION': '1.3.0'
        },
        serverType: 'geoserver',
        attributions: [attEOG]
    })
    }),
    new LayerTile({
    title: '2025 February',
    visible: false,
    source: new TileWMS({
        url: karagatanURL,
        params: {
            'LAYERS': 'karagatan:2025_february',
            'VERSION': '1.3.0'
        },
        serverType: 'geoserver',
        attributions: [attEOG]
    })
    }),
    new LayerTile({
    title: '2025 March',
    visible: false,
    source: new TileWMS({
        url: karagatanURL,
        params: {
            'LAYERS': 'karagatan:2025_march',
            'VERSION': '1.3.0'
        },
        serverType: 'geoserver',
        attributions: [attEOG]
    })
    }),
///////////weekly
    new LayerTile({
        title: '23 February - 01 March 2025',
        visible: false,
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:2025_0223_0301',
                'VERSION': '1.3.0'
            },
            serverType: 'geoserver',
            attributions: [attEOG]
        })
    }),
    new LayerTile({
        title: '02 - 08 March 2025',
        visible: false,
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:2025_0302_0308',
                'VERSION': '1.3.0'
            },
            serverType: 'geoserver',
            attributions: [attEOG]
        })
    }),
    new LayerTile({
        title: '09 - 15 March 2025',
        visible: false,
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:2025_0309_0315',
                'VERSION': '1.3.0'
            },
            serverType: 'geoserver',
            attributions: [attEOG]
        })
    }),
    new LayerTile({
        title: '16 - 22 March 2025',
        visible: false,
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:2025_0316_0322',
                'VERSION': '1.3.0'
            },
            serverType: 'geoserver',
            attributions: [attEOG]
        })
    }),
    new LayerTile({
        title: '23 - 29 March 2025',
        visible: false,
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:2025_0323_0329',
                'VERSION': '1.3.0'
            },
            serverType: 'geoserver',
            attributions: [attEOG]
        })  
    }),
    new LayerTile({
        title: '30 March - 05 April 2025',
        visible: true,
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:2025_0330_0405',
                'VERSION': '1.3.0'
            },
            serverType: 'geoserver',
            attributions: [attEOG]
        })  
    })
   ]
});

export default viirs_2025;