/* eslint-disable indent */
//CSS and Style Import
import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import 'ol-popup/src/ol-popup.css';
import './assets/css/kp.css';


//Source Import
import Map from 'ol/Map';
import View from 'ol/View';
import {transform} from 'ol/proj';
import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import ImageLayer from 'ol/layer/Image';
import ImageStatic from 'ol/source/ImageStatic';
// import {WindLayer} from 'ol-wind';


import SourceOSM from 'ol/source/OSM';
import TileWMS from 'ol/source/TileWMS';
import XYZ from 'ol/source/XYZ';
import BingMaps from 'ol/source/BingMaps';
import {
    Circle as CircleStyle,
    Fill,
    RegularShape,
    Stroke,
    Style,
    Text
  } from 'ol/style';
import {Draw, Modify, Snap} from 'ol/interaction';
import {LineString, Point} from 'ol/geom';
import {getArea, getLength} from 'ol/sphere';


//Controls
import MousePosition from 'ol/control/MousePosition.js';
import {Control, ZoomSlider} from 'ol/control';
import LayerSwitcher from 'ol-layerswitcher';
import {toStringHDMS} from 'ol/coordinate';
// import LayerDraw from 'ol/interaction/Draw';
// import Style from 'ol/style/Style';
// import Stroke from 'ol/style/Stroke';
// import LineString from 'ol/geom/LineString';
// import LayerVector from 'ol/layer/Vector';
// import LayerOverlay from 'ol/Overlay';
// import SourceVector from 'ol/source/Vector';
// import Popup from 'ol-popup';
// import Measure from 'ol/interaction';

//! [import-geolocation]
// import Feature from 'ol/Feature';
// import {circular} from 'ol/geom/Polygon';
// import Point from 'ol/geom/Point';
// import {fromLonLat} from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

(function(win, doc) {

const karagatanURL = process.env.GEOSERVER;

const attEOG = '<a href="https://eogdata.mines.edu/vbd/">Earth Observation Group, Payne Institute for Public Policy</a>';
const noaaBAA = '<a href="https://coralreefwatch.noaa.gov/product/5km/">NOAA Coral Reef Watch program</a>';
const PhilSA = '<a href="https://philsa.gov.ph/">Philippine Space Agency</a>';
const Copernicus = '<a href="https://rapidmapping.emergency.copernicus.eu/EMSR741/">Copernicus Emergency Management System </a>';
const Geoportal = '<a href="https:/www.geoportal.gov.ph">Geoportal Philippines</a>';
const showSegments = true;
const clearPrevious = false;
const source = new VectorSource();
const vector = new VectorLayer({
    source: source,
    style: function(feature) {
      return styleFunction(feature, showSegments);
    }
  });
//Query Layers
const wmsSource = new TileWMS({
  url: karagatanURL,
  params: {
    'LAYERS': ['karagatan:Municipal Waters', 'karagatan:Protected Areas', 'karagatan:DENR', 'karagatan:MARINA', 'karagatan:pnp_mg', 'karagatan:PCG', 'karagatan:bfar', 'karagatan:pnp'],
    'TILED': true,
    'VERSION': '1.3.0'
  },
  serverType: 'geoserver'

});
const view = new View({
    //National
    center: transform([125, 12], 'EPSG:4326', 'EPSG:3857'),
    zoom: 6

    // Visayan Sea
    // center: transform([121.50, 12.250], 'EPSG:4326', 'EPSG:3857'),
    // zoom: 7.5

    // MT Terranova Oil Spill
    // center: transform([120.75, 14.56], 'EPSG:4326', 'EPSG:3857'),
    // zoom: 10

});

//Map Layers
const map = new Map({
    target: 'map-container',
    layers: [
        /////// Basemap Layer Group////////////////
        new LayerGroup({
            title: 'Base maps',
            fold: 'close',
            layers: [
                new LayerTile({
                    title: 'NAMRIA base map',
                    type: 'base',
                    visible: true,
                    source: new XYZ({
                        attributions: [Geoportal],
                        url: 'http://basemapserver.geoportal.gov.ph/tiles/v2/PGP/{z}/{x}/{y}.png'
                    })
                }),
                new LayerTile({
                    title: 'Microsoft BingMaps',
                    type: 'base',
                    visible: false,
                    preload: Infinity,
                    source: new BingMaps({
                        key: 'AlKK3ON9IooxsERtWw6PLgGnvedYSDP8MkTo0-mfnWqfhf8QY1oOE3Pv2qKVCbCX',
                        imagerySet: 'AerialWithLabels',
                        maxZoom: 19
                    })
                }),
                new LayerTile({
                    title: 'Google Map',
                    type: 'base',
                    visible: false,
                    preload: Infinity,
                    source: new XYZ({
                        attributions: ['© Google <a href="https://developers.google.com/maps/terms">Terms of Use</a>'],
                        url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga'

                    })
                }),
                new LayerTile({
                    title: 'Open Street Map',
                    type: 'base',
                    visible: false,
                    source: new SourceOSM({
                        attributions: [
                            'All maps © <a href="https://www.opencyclemap.org/">OpenCycleMap</a>'
                        ]
                    })
                }),
                new LayerTile({
                    title: 'Philippine Relief Model',
                    type: 'base',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:Phil_HillshadeBedrock_15arcsec',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [
                            '<a href="https://www.ncei.noaa.gov/products/etopo-global-relief-model">NOAA NCEI. 2022: ETOPO 2022 15 Arc-Second Global Relief Model</a>'
                        ]
                    })
                })

            ]
        }),
        ////// Management Regimes////////////////
        new LayerGroup({
            title: 'Management Regimes',
            fold: 'close',
            layers: [
            new LayerTile({
                title: '<img src="https://karagatanpatrol.org/symbols/nipas.png" alt="" width="12" height="12">National Integrated Protected Areas System',
                visible: true,
                source: new TileWMS({
                    url: karagatanURL,
                    params: {
                        'LAYERS': 'karagatan:Protected Areas',
                        'VERSION': '1.3.0'
                    },
                    serverType: 'geoserver'
                    // crossOrigin: 'anonymous'
                })
            }),
            new LayerTile({
                title: '<img src="https://karagatanpatrol.org/symbols/fma.png" alt="" width="12" height="12">Fisheries Management Areas',
                visible: true,
                source: new TileWMS({
                    url: karagatanURL,
                    params: {
                        'LAYERS': 'karagatan:fma',
                        'VERSION': '1.3.0'
                    },
                    serverType: 'geoserver'
                })
            }),

            new LayerTile({
                title: '<img src="https://karagatanpatrol.org/symbols/ecs.png" alt="" width="12" height="12">Philippine Extended Continental Shelf',
                visible: true,
                source: new TileWMS({
                    url: karagatanURL,
                    params: {
                        'LAYERS': 'karagatan:philecs',
                        'VERSION': '1.3.0'
                    },
                    serverType: 'geoserver'
                })
            }),
            new LayerTile({
                title: '<img src="https://karagatanpatrol.org/symbols/internal_wat.png" alt="" width="12" height="12">Philippine Territorial/Internal Waters',
                visible: false,
                source: new TileWMS({
                    url: karagatanURL,
                    params: {
                        'LAYERS': 'karagatan:territorialwaters',
                        'VERSION': '1.3.0'
                    },
                    serverType: 'geoserver'
                })
            }),
            new LayerTile({
                title: '<img src="https://karagatanpatrol.org/symbols/munwat.png" alt="" width="12" height="12">Municipal waters-archipelagic principle',
                visible: true,
                source: new TileWMS({
                    url: karagatanURL,
                    params: {
                        'LAYERS': 'karagatan:Municipal Waters',
                        'VERSION': '1.3.0'
                    },
                    attributions: [Geoportal],
                    serverType: 'geoserver'
                })
            }),
            new LayerTile({
                title: '<img src="https://karagatanpatrol.org/symbols/munwat_mainland.png" alt="" width="12" height="12">Municipal waters-mainland principle(hypothetical)',
                visible: false,
                source: new TileWMS({
                    url: karagatanURL,
                    params: {
                        'LAYERS': 'karagatan:munwaters_mainland',
                        'VERSION': '1.3.0'
                    },
                    serverType: 'geoserver'
                })
            }),

            new LayerTile({
                title: '<img src="https://karagatanpatrol.org/symbols/kig.png" alt="" width="12" height="12">Kalayaan Island Group',
                source: new TileWMS({
                    url: karagatanURL,
                    params: {
                        'LAYERS': 'karagatan:kalayaanisgroup',
                        'VERSION': '1.3.0'
                    },
                    serverType: 'geoserver'
                })
            })
        ]
        }),

        
        ///////Law Enforcement Agencies
        new LayerGroup({
            title: 'Law Enforcement Agencies',
            fold: 'close',
            layers: [
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/denr.png" alt="" width="16" height="16"> Department of Environment and Natural Resources (DENR)',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:DENR',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver'
                    })
                }),
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/Marina.png" alt="" width="16" height="16"> Maritime Industry Authority (MARINA)',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:MARINA',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver'
                    })
                }),
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/pnpmaritime.png" alt="" width="16" height="16"> PNP Maritime Group (PNP-MG)',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:pnp_mg',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver'
                    })
                }),
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/pcg.png" alt="" width="16" height="16"> Philippine Coast Guard (PCG)',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:PCG',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver'
                    })
                }),
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/bfar.png" alt="" width="16" height="16"> Bureau of Fisheries and Aquatic Resources (BFAR)',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:bfar',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver'
                    })
                }),
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/pnp.png" alt="" width="16" height="16"> Philippine National Police (PNP)',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:pnp',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver'
                    })
                })
            ]
        }),
        ///NOAA Coral Bleaching Alert Area (7-day)
        new LayerGroup({
            title: '<a href="https://coralreefwatch.noaa.gov/product/5km/">NOAA Coral Bleaching Alert Area (7-day)</a>',
            fold: 'close',
            layers: [
                ///Alert Watch
                new LayerGroup({
                    title : '<img src="https://karagatanpatrol.org/symbols/bleach_watch.png" alt="" width="12" height="12"> Watch',
                    fold  : 'close',
                    layers : [
                        new LayerTile({
                            title: '01 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:01jun2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '08 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:08jun2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '15 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:15jun2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '22 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:22jun2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '29 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:29jun2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '06 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:06jul2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '13 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:13jul2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '20 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20jul2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '27 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:27jul2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '03 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:03aug2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '10 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:10aug2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '17 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:17aug2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '24 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:24aug2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '31 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:31aug2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '07 September 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:07sep2024_BAA_watch',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        })

                    ]
                }),
                ///Alert Warning
                new LayerGroup({
                    title : '<img src="https://karagatanpatrol.org/symbols/bleach_warning.png" alt="" width="12" height="12"> Warning (Risk of Possible Bleaching)',
                    fold : 'close',
                    layers: [
                        new LayerTile({
                            title: '01 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:01jun2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '08 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:08jun2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '15 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:15jun2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '22 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:22jun2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '29 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:29jun2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '06 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:06jul2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '13 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:13jul2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '20 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20jul2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '27 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:27jul2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '03 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:03aug2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '10 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:10aug2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '17 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:17aug2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '24 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:24aug2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '31 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:31aug2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '07 September 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:07sep2024_BAA_warning',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        })
                    ]
                }),
                ///Alert Level 1
                new LayerGroup({
                    title : '<img src="https://karagatanpatrol.org/symbols/bleach_level1.png" alt="" width="12" height="12"> Alert Level 1 (Risk of Reef-Wide Bleaching)',
                    fold : 'close',
                    layers: [
                        new LayerTile({
                            title: '01 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:01jun2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '08 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:08jun2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '15 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:15jun2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '22 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:22jun2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '29 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:29jun2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '06 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:06jul2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '13 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:13jul2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '20 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20jul2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '27 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:27jul2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '03 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:03aug2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '10 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:10aug2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '17 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:17aug2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '24 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:24aug2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '31 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:31aug2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '07 September August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:07sep2024_BAA_level1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        })
                    ]
                }),
                ///Alert Level 2
                new LayerGroup({
                    title : '<img src="https://karagatanpatrol.org/symbols/bleach_level2.png" alt="" width="12" height="12"> Alert Level 2 (Risk of Reef-Wide Bleaching with Mortality of Heat-Sensitive Corals)',
                    fold : 'close',
                    layers: [
                        new LayerTile({
                            title: '01 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:01jun2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '08 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:08jun2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '15 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:15jun2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '22 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:22jun2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '29 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:29jun2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '06 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:06jul2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '13 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:13jul2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '20 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20jul2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '27 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:27jul2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '03 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:03aug2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '10 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:10aug2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '17 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:17aug2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '24 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:24aug2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '31 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:31aug2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '07 September 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:07sep2024_BAA_level2',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        })
                    ]
                }),
                ///Alert Level 3
                new LayerGroup({
                    title : '<img src="https://karagatanpatrol.org/symbols/bleach_level3.png" alt="" width="12" height="12"> Alert Level 3 (Risk of Multi-Species Mortality)',
                    fold : 'close',
                    layers: [
                        new LayerTile({
                            title: '01 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:01jun2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '08 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:08jun2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '15 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:15jun2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '22 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:22jun2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '29 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:29jun2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '06 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:06jul2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '13 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:13jul2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '20 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20jul2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '27 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:27jul2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '03 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:03aug2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '10 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:10aug2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '17 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:17aug2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '24 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:24aug2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '31 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:31aug2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '07 September 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:07sep2024_BAA_level3',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        })

                    ]
                }),
                ///Alert Level 4
                new LayerGroup({
                    title : '<img src="https://karagatanpatrol.org/symbols/bleach_level4.png" alt="" width="12" height="12"> Alert Level 4 (Risk of Severe, Multi-Species Mortality (> 50% of corals))',
                    fold : 'close',
                    layers: [
                        new LayerTile({
                            title: '01 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:01jun2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '08 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:08jun2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '15 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:15jun2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '22 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:22jun2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '29 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:29jun2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '06 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:06jul2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '13 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:13jul2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '20 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20jul2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '27 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:27jul2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '03 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:03aug2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '10 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:10aug2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '17 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:17aug2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '24 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:24aug2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '31 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:31aug2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '07 September 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:07sep2024_BAA_level4',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        })
                    ]
                }),
                ///Alert Level 5
                new LayerGroup({
                    title : '<img src="https://karagatanpatrol.org/symbols/bleach_level5.png" alt="" width="12" height="12"> Alert Level 5 (Risk of Near Complete Mortality (> 80% of corals))',
                    fold : 'close',
                    layers: [
                        new LayerTile({
                            title: '01 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:01jun2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '08 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:08jun2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '15 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:15jun2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '22 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:22jun2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '29 June 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:29jun2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '06 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:06jul2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '13 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:13jul2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '20 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20jul2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '27 July 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:27jul2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '03 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:03aug2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '10 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:10aug2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '17 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:17aug2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '24 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:24aug2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '31 August 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:31aug2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        }),
                        new LayerTile({
                            title: '07 September 2024',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:07sep2024_BAA_level5',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [noaaBAA]
                            })
                        })
                    ]
                }),
                ]
        }),
        
        ////////MT TERRANOVA oil spill near Limay, Bataan
        new LayerGroup({
            title: 'Oil Spill Monitor',
            fold: 'close',
            layers: [
                new LayerGroup({
                    title: '<img src="https://karagatanpatrol.org/symbols/oilspillmon.png" alt="" width="12" height="12">Copernicus Emergency Management Service (EMS)',
                    fold: 'close',
                    layers: [
                        new LayerTile({
                            title: '<a href="https://rapidmapping.emergency.copernicus.eu/EMSR741/"> 26 July 2024 (RCM)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:emsr741_v2_07262024_rcm',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [Copernicus]
                            }),          
                        }),
                        new LayerTile({
                            title: '<a href="https://rapidmapping.emergency.copernicus.eu/EMSR741/"> 26 July 2024 (PAZ)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:emsr741_v2_07262024_paz',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [Copernicus]
                            }),          
                        }),
                        new LayerTile({
                            title: '<a href="https://rapidmapping.emergency.copernicus.eu/EMSR741/"> 27 July 2024 (TerraSAR-X)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:emsr741_v2_07272024_terx',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [Copernicus]
                            })
                        }),
                        new LayerTile({
                            title: '<a href="https://rapidmapping.emergency.copernicus.eu/EMSR741/"> 28 July 2024 (Sentinel 1)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:emsr741_v2_07282024_s1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [Copernicus]
                            })
                        }),
                        new LayerTile({
                            title: '<a href="https://rapidmapping.emergency.copernicus.eu/EMSR741/"> 30 July 2024 (Sentinel 1)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:emsr741_v2_07302024_s1',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [Copernicus]
                            })
                        }),
                    ]
                }),
                new LayerGroup({
                    title: '<img src="https://karagatanpatrol.org/symbols/oilspillmon.png" alt="" width="12" height="12">Philippine Space Agency Oil Spill Monitor',
                    fold: 'close',
                    layers: [
                        new LayerTile({
                            title: '<a href="https://www.facebook.com/share/p/qcDS85GD9ptX1fmr/"> 26 July 2024 (RCM)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20240726_terranova',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [PhilSA]
                            })
                        }),
                        new LayerTile({
                            title: '<a href="https://www.facebook.com/share/p/uBy3WSry9ZWzurCE/"> 27 July 2024 (RCM)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20240727_terranova',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [PhilSA]
                            })
                        }),
                        new LayerTile({
                            title: '<a href="https://www.facebook.com/share/p/TJybUXEx2xemRZcF/"> 27 July 2024 (GeoEye)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20240727_geoeye_oilspill',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [PhilSA]
                            })
                        }),
                       
                        new LayerTile({
                            title: '<a href="https://www.facebook.com/share/p/X4poPrFkLEs9z3tv/"> 27 July 2024 (Tandem-X)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20240727_tandex_oilspill',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [PhilSA]
                            })
                        }),
                        new LayerTile({
                            title: '<a href="https://www.facebook.com/share/p/p8cpao6bNS9gEH6F/"> 28 July 2024 (Sentinel 1)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20240728_s1_oilspill',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [PhilSA]
                            })
                        }),                        
                        new LayerTile({
                            title: '<a href="https://www.facebook.com/share/p/8NhS43bDGXnmK145/"> 28 July 2024 (RCM)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20240728_rcm_revised',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [PhilSA]
                            })
                        }),
                        new LayerTile({
                            title: '<a href="https://www.facebook.com/share/p/TJybUXEx2xemRZcF/"> 29 July 2024 (EOS-4)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20240729_eos4_oilspill',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [PhilSA]
                            })
                        }),
                        new LayerTile({
                            title: '<a href="https://www.facebook.com/share/p/p8cpao6bNS9gEH6F/"> 30 July 2024 (Sentinel 1)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:20240730_s1_oilspill_merged_kp',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [PhilSA]
                            })
                        })
                        
                    ]
                }),
                new LayerGroup ({
                    title: '<img src="https://karagatanpatrol.org/symbols/oilsightings.png" alt="" width="10" height="12">  <a href="https://docs.google.com/spreadsheets/d/14S02vQiSEAK0MrX_jRC8EkejZwNhtS4lUhDHW93XRlU/edit?usp=sharing"> Oil Sightings</a>',
                    fold: 'close',
                    layers: [
                        new LayerTile({
                            title: '<img src="https://karagatanpatrol.org/symbols/oilsightings.png" alt="" width="10" height="12">30 July 2024</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:oilsightings_20240730',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                            })
                        }),
                        new LayerTile({
                            title: '<img src="https://karagatanpatrol.org/symbols/oilsightings.png" alt="" width="10" height="12">29 July 2024</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:oilsightings_20240729',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                            })
                        }),
                        new LayerTile({
                            title: '<img src="https://karagatanpatrol.org/symbols/oilsightings.png" alt="" width="10" height="12">28 July 2024</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:oilsightings_20240728',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                            })
                        })
                        
                    ]
                }),
                new LayerGroup ({
                    title: '<img src="https://karagatanpatrol.org/symbols/oil_spill1.png" alt="" width="10" height="12">Point Source Location',
                    fold: 'close',
                    layers: [
                        new LayerTile({
                            title: '<img src="https://karagatanpatrol.org/symbols/oil_spill1.png" alt="" width="10" height="12"><a href="https://www.facebook.com/share/p/qcDS85GD9ptX1fmr/">MT Terranova Location (July 25, 2024)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:mtterranova_oilspill',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [PhilSA]
                            })
                        }),
                        new LayerTile({
                            title: '<img src="https://karagatanpatrol.org/symbols/oil_spill1.png" alt="" width="10" height="12">MTKR Jason Bradley (July 27, 2024 approx. locaton)</a>',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:mtkrjasonbradley_location_pt',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                // attributions: [PhilSA]
                            })
                        }),
                    ]
                })
                                ]
        }),
        ////////Closed season areas
        new LayerGroup({
            title: '<img src="https://karagatanpatrol.org/symbols/closedseason.png" alt="" width="12" height="12"> Closed Season Areas',
            fold: 'close',
            layers: [
                new LayerTile({
                    title: 'Zamboanga Peninsula - 15 Nov to 15 Feb (2024)',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:closed_season_zamboanga',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver'
                    })
                }),

                new LayerTile({
                    title: 'Northern Palawan - 01 Nov to 31 Jan',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:closed_season_palawan',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver'
                    })
                }),
                new LayerTile({
                    title: 'Visayan Sea - 15 Nov to 15 Feb',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:closed_season_visayas',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver'
                    })
                })
                // new LayerTile({
                //     title: 'Balayan Bay - 17 Nov 2019 to 17 Jan 2020',
                //     visible: false,
                //     source: new TileWMS({
                //         url: karagatanURL,
                //         params: {
                //             'LAYERS': 'karagatan:BalayanClosedSeason',
                //             'VERSION': '1.3.0'
                //         },
                //         serverType: 'geoserver'
                //     })
                // })

            ]
        }),
/////Poverty Reduction
/*      new LayerGroup({
            title: 'Poverty Incidence Among Population',
            fold: 'close',
            layers: [                  
                new LayerTile({
                    title: '2018 Poverty Incidence Among Population',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:2018_popovertyinc',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        // crossOrigin: 'anonymous',
                        attributions: ['<a href="https://openstat.psa.gov.ph/PXWeb/pxweb/en/DB/DB__1E__FY/0031E3DF020.px/?rxid=81880cf7-ddcc-4d3f-8c80-a3246bc73bd3">Philippine Statistics Authority</a>']
                    })
                }),
                new LayerTile({
                    title: '2021 Poverty Incidence Among Population',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:2021_popovertyinc',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        // crossOrigin: 'anonymous',
                        attributions: ['<a href="https://openstat.psa.gov.ph/PXWeb/pxweb/en/DB/DB__1E__FY/0031E3DF020.px/?rxid=81880cf7-ddcc-4d3f-8c80-a3246bc73bd3">Philippine Statistics Authority</a>']
                    })
                }),
                new LayerTile({
                    title: '2023 Poverty Incidence Among Population',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:2023_popovertyinc',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        // crossOrigin: 'anonymous',
                        attributions: ['<a href="https://openstat.psa.gov.ph/PXWeb/pxweb/en/DB/DB__1E__FY/0031E3DF020.px/?rxid=81880cf7-ddcc-4d3f-8c80-a3246bc73bd3">Philippine Statistics Authority</a>']
                    })
                }),

                ]
        }),
*/
        //////// VIIRS Boat Detection Monthly Layer Group////////////////
        new LayerGroup({
            title: '<img src="https://karagatanpatrol.org/symbols/viirs_bd.png" alt="" width="16" height="16">VIIRS Boat Detection',
            fold: 'open',
            layers: [
                //2017
                new LayerGroup({
                    title: '2017',
                    fold: 'close',
                    layers: [
                        new LayerTile({
                            title: '2017 January',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2017_January',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2017 February',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2017_February',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2017 March',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2017_March',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2017 April',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2017_April',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2017 May',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2017_May',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2017 June',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2017_June',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2017 July',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2017_July',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2017 August',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2017_August',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2017 September',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2017_September',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2017 October',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2017_October',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2017 November',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2017_November',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2017 December',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2017_December',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        })
                        ]
                    }),
                //2018
                new LayerGroup({
                    title: '2018',
                    fold: 'close',
                    layers: [
                        new LayerTile({
                            title: '2018 January',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2018_January',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2018 February',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2018_February',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2018 March',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2018_March',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2018 April',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2018_April',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2018 May',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2018_May',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2018 June',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2018_June',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2018 July',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2018_July',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2018 August',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2018_August',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2018 September',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2018_September',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2018 October',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2018_October',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2018 November',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2018_November',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2018 December',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2018_December',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        })
                        ]
                    }),
                //2019
                new LayerGroup({
                    title: '2019',
                    fold: 'close',
                    layers: [
                        new LayerTile({
                            title: '2019 January',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2019_January',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2019 February',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2019_February',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2019 March',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2019_March',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2019 April',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2019_April',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2019 May',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2019_May',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2019 June',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2019_June',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2019 July',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2019_July',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2019 August',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2019_August',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2019 September',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2019_September',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2019 October',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2019_October',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2019 November',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2019_November',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2019 December',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2019_December',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        })
                        ]
                 }),
                //2020
                 new LayerGroup({
                    title: '2020',
                    fold: 'close',
                    layers: [
                        new LayerTile({
                            title: '2020 January',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2020_January',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2020 February',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2020_February',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2020 March',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2020_March',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2020 April',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2020_April',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2020 May',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2020_May',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2020 June',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2020_June',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2020 July',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2020_July',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2020 August',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2020_August',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2020 September',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2020_September',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2020 October',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2020_October',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2020 November',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2020_November',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2020 December',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2020_December',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        })
                    ]
                 }),
                //2021
                new LayerGroup({
                    title: '2021',
                    fold: 'close',
                    layers: [
                        new LayerTile({
                            title: '2021 January',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2021_January',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2021 February',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2021_February',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2021 March',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2021_March',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2021 April',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2021_April',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2021 May',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2021_May',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2021 June',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2021_June',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2021 July',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2021_July',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2021 August',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2021_August',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2021 September',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2021_September',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2021 October',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2021_October',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2021 November',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2021_November',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2021 December',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2021_December',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        })
                    ]
                 }),
                //2022
                new LayerGroup({
                    title: '2022',
                    fold: 'close',
                    layers: [
//////////////////////* Monthly updates here*
                        new LayerTile({
                            title: '2022 January',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2022_January',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2022 February',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2022_February',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2022 March',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2022_March',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2022 April',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2022_April',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2022 May',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2022_May',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2022 June',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2022_June',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2022 July',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2022_July',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2022 August (NOAA-20)',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2022_August',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2022 September',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2022_September',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2022 October',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2022_October',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2022 November',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2022_November',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2022 December',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2022_December',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        })
                    ]
                 }),

                 //2023
                new LayerGroup({
                    title: '2023',
                    fold: 'close',
                    layers: [
//////////////////////* Monthly updates here*
                        new LayerTile({
                            title: '2023 January',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2023_January',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2023 February',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2023_February',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2023 March',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2023_March',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2023 April',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2023_April',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2023 May',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2023_May',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2023 June',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2023_June',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2023 July',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2023_July',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2023 August',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2023_August',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2023 September',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2023_September',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2023 October',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2023_october',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2023 November',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2023_november',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2023 December',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2023_December',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        })
                    ]
                 }),

                 //2024
                new LayerGroup({
                    title: '2024',
                    fold: 'close',
                    layers: [
//////////////////////* Monthly updates here*
                        new LayerTile({
                            title: '2024 January',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2024_January',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2024 February',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2024_February',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2024 March',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2024_March',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2024 April',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2024_April',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2024 May',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2024_May',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2024 June',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2024_June',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2024 July',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2024_july',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2024 August',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2024_aug',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2024 September',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2024_september',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2024 October',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2024_october',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2024 November',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2024_november',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                        new LayerTile({
                            title: '2024 December',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:2024_december',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                attributions: [attEOG]
                            })
                        }),
                    ]
                }),
             
///////////2025
                 new LayerGroup({
                    title: '2025',
                    fold: 'open',
                    layers: [
///////////monthly
                new LayerTile({
                    title: '2025 January',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:2025_january',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [attEOG]
                    })
                }),
                new LayerTile({
                    title: '2025 February',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:2025_february',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [attEOG]
                    })
                }),
                new LayerTile({
                    title: '2025 March',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:2025_march',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [attEOG]
                    })
                }),
///////////weekly

                    new LayerTile({
                        title: '23 February - 01 March 2025',
                        visible: false,
                        source: new TileWMS({
                            url: karagatanURL,
                            params: {
                                'LAYERS': 'karagatan:2025_0223_0301',
                                'VERSION': '1.3.0'
                            },
                            serverType: 'geoserver',
                            attributions: [attEOG]
                        })
                    }),
                    new LayerTile({
                        title: '02 - 08 March 2025',
                        visible: false,
                        source: new TileWMS({
                            url: karagatanURL,
                            params: {
                                'LAYERS': 'karagatan:2025_0302_0308',
                                'VERSION': '1.3.0'
                            },
                            serverType: 'geoserver',
                            attributions: [attEOG]
                        })
                    }),
                    new LayerTile({
                        title: '09 - 15 March 2025',
                        visible: false,
                        source: new TileWMS({
                            url: karagatanURL,
                            params: {
                                'LAYERS': 'karagatan:2025_0309_0315',
                                'VERSION': '1.3.0'
                            },
                            serverType: 'geoserver',
                            attributions: [attEOG]
                        })
                    }),
                    new LayerTile({
                        title: '16 - 22 March 2025',
                        visible: false,
                        source: new TileWMS({
                            url: karagatanURL,
                            params: {
                                'LAYERS': 'karagatan:2025_0316_0322',
                                'VERSION': '1.3.0'
                            },
                            serverType: 'geoserver',
                            attributions: [attEOG]
                        })
                    }),
                    new LayerTile({
                        title: '23 - 29 March 2025',
                        visible: true,
                        source: new TileWMS({
                            url: karagatanURL,
                            params: {
                                'LAYERS': 'karagatan:2025_0323_0329',
                                'VERSION': '1.3.0'
                            },
                            serverType: 'geoserver',
                            attributions: [attEOG]
                        })
                    })
                    ]
                 }),

                ]
            }),

        /////Atin ang Kinse Layers
        new LayerGroup({
            title: '<a href="https://karagatanpatrol.org/atin_ang_kinse" target="_blank">Atin ang Kinse</a>',
            fold: 'close',
            layers: [
                ///20 fathoms in municipal waters
                new LayerGroup({
                    title: 'Municipal Waters Bathymetry',
                    fold: 'close',
                    layers: [
                        new LayerTile({
                            title: '<img src="https://karagatanpatrol.org/symbols/gebco_below36.png" alt="" width="12" height="12"> deeper than 20 fathoms (deeper than ~36m)',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:gebco_fmamunwat_morethan36',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                // crossOrigin: 'anonymous',
                                attributions: ['<a href="https:/www.gebco.net">GEBCO</a>']
                            })
                        }),
                        new LayerTile({
                            title: '<img src="https://karagatanpatrol.org/symbols/gebco_0_36.png" alt="" width="12" height="12"> 7 - 20 fathoms (13 - ~36m)',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:gebco_fmamunwat_13_36',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                // crossOrigin: 'anonymous',
                                attributions: ['<a href="https:/www.gebco.net">GEBCO</a>']
                            })
                        }),
                        new LayerTile({
                            title: '<img src="https://karagatanpatrol.org/symbols/gebco_0_13.png" alt="" width="12" height="12"> 0 - 7 fathoms (0 - ~13m)',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:gebco_fmamunwat_0_13',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                // crossOrigin: 'anonymous',
                                attributions: ['<a href="https:/www.gebco.net">GEBCO</a>']
                            })
                        })
                    ]
                }),
                ////// Coastal Habitats////////////////
                new LayerGroup({
                    title: 'Coastal Habitats',
                    fold: 'close',
                    layers: [
                        new LayerTile({
                            title: '<img src="https://karagatanpatrol.org/symbols/corals.png" alt="" width="12" height="12">Coral reefs',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:crm_geoportal_reefs',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                // crossOrigin: 'anonymous',
                                attributions: [Geoportal]
                            })
                        }),
                        new LayerTile({
                            title: '<img src="https://karagatanpatrol.org/symbols/seaweeds.png" alt="" width="12" height="12">Seagrasses / Seaweeds',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:crm_geoportal_seagrass_seaweeds',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                // crossOrigin: 'anonymous',
                                attributions: [Geoportal]
                            })
                        }),
                        new LayerTile({
                            title: '<img src="https://karagatanpatrol.org/symbols/mangroves.png" alt="" width="12" height="12">Mangroves',
                            visible: false,
                            source: new TileWMS({
                                url: karagatanURL,
                                params: {
                                    'LAYERS': 'karagatan:crm_geoportal_mangroves',
                                    'VERSION': '1.3.0'
                                },
                                serverType: 'geoserver',
                                // crossOrigin: 'anonymous',
                                attributions: [Geoportal]
                            })
                        })
                    ]
                })
            ]
        }),


        ////////Resolutions Opposing HB 7853

        // new LayerGroup({
        //     title: 'Resolutions Opposing HB 7853',
        //     fold: 'close',
        //     layers: [
        //         new LayerTile({
        //             title: '<a href="/hb7853">Fisherfolks Association with Resolutions Opposing HB 7853</a>',
        //             visible: false,
        //             source: new TileWMS({
        //                 url: karagatanURL,
        //                 params: {
        //                     'LAYERS': 'karagatan:hb7853_fishers',
        //                     'VERSION': '1.3.0'
        //                 },
        //                 serverType: 'geoserver',
        //                 attributions: [attEOG]
        //             })
        //         }),
        //         new LayerTile({
        //             title: '<a href="/hb7853">LGUs with Resolutions Opposing HB 7853</a>',
        //             visible: false,
        //             source: new TileWMS({
        //                 url: karagatanURL,
        //                 params: {
        //                     'LAYERS': 'karagatan:hb7853_mun',
        //                     'VERSION': '1.3.0'
        //                 },
        //                 serverType: 'geoserver',
        //                 attributions: [attEOG]
        //             })
        //         })
        //         ]
        //         }),

        vector
    ],
    view: view
});

//Layer InformationQuery 

map.on(['click'], function(evt) {
    //Check if the layer is visible

    // if (!wmsLayer3.getVisible()) return;

    //Proceed with the query if the layer is visible
    document.getElementById('info').innerHTML = '';

    const viewResolution = 10;
    /** @type {number} *//** (view.getResolution())*/
    const url = wmsSource.getFeatureInfoUrl(
      evt.coordinate, viewResolution, 'EPSG:3857',
      {
        'INFO_FORMAT': 'text/html',
        'QUERY_LAYERS': ['karagatan:Municipal Waters','karagatan:Protected Areas', 'karagatan:DENR', 'karagatan:MARINA', 'karagatan:pnp_mg', 'karagatan:PCG', 'karagatan:bfar', 'karagatan:pnp']
    });

    if (url) {
      fetch(url)
        .then(function(response) {
          return response.text();
        })
        .then(function(html) {
          document.getElementById('info').innerHTML = html;
        });
    }
  });
////

//Controls
//Layer Swticher
const layerSwitcher = new LayerSwitcher();
map.addControl(layerSwitcher);


////// GPS Control

// const loc_source = new VectorSource();
// const loc_layer = new VectorLayer({
//     source: loc_source
// });
// map.addLayer(loc_layer);

// navigator.geolocation.watchPosition(function(pos) {
//     const coords = [pos.coords.longitude, pos.coords.latitude];
//     const accuracy = circular(coords, pos.coords.accuracy);
//     source.clear(true);
//     source.addFeatures([
//         new Feature(accuracy.transform('EPSG:4326', map.getView().getProjection())),
//         new Feature(new Point(fromLonLat(coords)))
//     ]);
// }, function(error) {
//     alert(`GPS OFF: ${error.message}`);
// }, {
//     enableHighAccuracy: true
// });

// const locate = document.createElement('div');
// locate.className = 'ol-control ol-unselectable locate';
// locate.innerHTML = '<button title="Current Location">◎</button>';
// locate.addEventListener('click', function() {
//     if (!source.isEmpty()) {
//         map.getView().fit(source.getExtent(), {
//             maxZoom: 18,
//             duration: 500
//         });
//     }
// });
// map.addControl(new Control({
//     element: locate
// }));

//Zoom slider
const zoomslider = new ZoomSlider();
map.addControl(zoomslider);


//Mouse Position Control
const mousePosition = new MousePosition({
    coordinateFormat: function(coord) {
        return toStringHDMS(coord);
    },
    projection: 'EPSG:4326',
    target: document.getElementById('custom-mouse-position')
});
map.addControl(mousePosition);


/** Measure Tool */

const style = new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)'
    }),
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.5)',
      lineDash: [10, 10],
      width: 2
    }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      })
    })
  });

const labelStyle = new Style({
    text: new Text({
      font: '14px Calibri,sans-serif',
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)'
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      padding: [3, 3, 3, 3],
      textBaseline: 'bottom',
      offsetY: -15
    }),
    image: new RegularShape({
      radius: 8,
      points: 3,
      angle: Math.PI,
      displacement: [0, 10],
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.7)'
      })
    })
  });

const tipStyle = new Style({
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)'
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)'
      }),
      padding: [2, 2, 2, 2],
      textAlign: 'left',
      offsetX: 15
    })
  });

const modifyStyle = new Style({
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)'
      })
    }),
    text: new Text({
      text: 'Drag to modify',
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)'
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      padding: [2, 2, 2, 2],
      textAlign: 'left',
      offsetX: 15
    })
  });

const segmentStyle = new Style({
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)'
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)'
      }),
      padding: [2, 2, 2, 2],
      textBaseline: 'bottom',
      offsetY: -12
    }),
    image: new RegularShape({
      radius: 6,
      points: 3,
      angle: Math.PI,
      displacement: [0, 8],
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)'
      })
    })
  });

const segmentStyles = [segmentStyle];

const formatLength = function(line) {
    const length = getLength(line);
    let output;
    if (length > 100) {
      output = Math.round((length / 1000) * 100) / 100 + ' km';
    } else {
      output = Math.round(length * 100) / 100 + ' m';
    }
    return output;
  };

const formatArea = function(polygon) {
    const area = getArea(polygon);
    let output;
    if (area > 10000) {
      output = Math.round((area / 1000000) * 100) / 100 + ' km\xB2';
    } else {
      output = Math.round(area * 100) / 100 + ' m\xB2';
    }
    return output;
  };


const modify = new Modify({source: source, style: modifyStyle});

let tipPoint;

function styleFunction(feature, segments, drawType, tip) {
    const styles = [style];
    const geometry = feature.getGeometry();
    const type = geometry.getType();
    let point, label, line;
    if (!drawType || drawType === type) {
      if (type === 'Polygon') {
        point = geometry.getInteriorPoint();
        label = formatArea(geometry);
        line = new LineString(geometry.getCoordinates()[0]);
      } else if (type === 'LineString') {
        point = new Point(geometry.getLastCoordinate());
        label = formatLength(geometry);
        line = geometry;
      }
    }
    if (segments && line) {
      let count = 0;
      line.forEachSegment(function(a, b) {
        const segment = new LineString([a, b]);
        const label = formatLength(segment);
        if (segmentStyles.length - 1 < count) {
          segmentStyles.push(segmentStyle.clone());
        }
        const segmentPoint = new Point(segment.getCoordinateAt(0.5));
        segmentStyles[count].setGeometry(segmentPoint);
        segmentStyles[count].getText().setText(label);
        styles.push(segmentStyles[count]);
        count++;
      });
    }
    if (label) {
      labelStyle.setGeometry(point);
      labelStyle.getText().setText(label);
      styles.push(labelStyle);
    }
    if (
      tip &&
      type === 'Point' &&
      !modify.getOverlay().getSource().getFeatures().length
    ) {
      tipPoint = geometry;
      tipStyle.getText().setText(tip);
      styles.push(tipStyle);
    }
    return styles;
  }

map.addInteraction(modify);

let draw; // global so we can remove it later

const snapInteraction = new Snap({
    source: vector.getSource()
  });

function addInteraction(geomType) {
    const drawType = geomType;
    const activeTip =
      'Click to continue drawing the ' +
      (drawType === 'Polygon' ? 'polygon' : 'line');
    const idleTip = 'Click to start measuring';
    let tip = idleTip;
    draw = new Draw({
      source: source,
      type: drawType,
      style: function(feature) {
        return styleFunction(feature, showSegments, drawType, tip);
      }
    });
    draw.on('drawstart', function() {
        if (clearPrevious) {
          source.clear();
        }
        modify.setActive(false);
        tip = activeTip;
      });
    draw.on('drawend', function() {
      modifyStyle.setGeometry(tipPoint);
      modify.setActive(true);
      map.once('pointermove', function() {
        modifyStyle.setGeometry();
      });
      tip = idleTip;
    });
    modify.setActive(true);
    map.addInteraction(snapInteraction);
    map.addInteraction(draw);
  }


/** Measure Control */

//distance button
const measure_dist_btn = document.createElement('div');
measure_dist_btn.className = 'measure-dist ol-unselectable ol-control ';
measure_dist_btn.innerHTML = '<button title="Distance Measurement"><img src="/assets/image/measure_dist.png"/></button>';
measure_dist_btn.addEventListener('click', function() {
    map.removeInteraction(draw);
    const geomType = 'LineString';
    addInteraction(geomType);
});
map.addControl(new Control({
    element: measure_dist_btn
}));

//area button
const measure_area_btn = document.createElement('div');
measure_area_btn.className = 'measure-area ol-unselectable ol-control ';
measure_area_btn.innerHTML = '<button title="Calculate Area"><img src="/assets/image/measure_area.png" /></button>';
measure_area_btn.addEventListener('click', function() {
    map.removeInteraction(draw);
    const geomType = 'Polygon';
    addInteraction(geomType);
});
map.addControl(new Control({
    element: measure_area_btn
}));


//clear drawing button
const clear_btn = document.createElement('div');
clear_btn.className = 'clear ol-unselectable ol-control ';
clear_btn.innerHTML = '<button title="Clear Drawing" ><img src="/assets/image/clear.png" /></button>';
clear_btn.addEventListener('click', function() {
    source.clear();
    map.removeInteraction(draw);
    addInteraction;
});
map.addControl(new Control({
    element: clear_btn
}));


//analytics link
const analytics_btn = document.createElement('div');
analytics_btn.className = 'analytics ol-unselectable ol-control ';
analytics_btn.innerHTML = '<button title="Data Analytics" ><img src="/assets/image/analytics.png" /></button>';
analytics_btn.addEventListener('click', function() {
    const url = 'http://karagatanpatrol.org/analytics/';
    window.open(url, '_blank');

});
map.addControl(new Control({
    element: analytics_btn
}));

})(window, document);
