import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const karagatanURL = process.env.GEOSERVER;

const povertyIncidenceLayers= new LayerGroup({
    title: 'Poverty Incidence Among Population',
    fold: 'close',
    layers: [                  
        new LayerTile({
            title: '2018 Poverty Incidence Among Population',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_popovertyinc',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                // crossOrigin: 'anonymous',
                attributions: ['<a href="https://openstat.psa.gov.ph/PXWeb/pxweb/en/DB/DB__1E__FY/0031E3DF020.px/?rxid=81880cf7-ddcc-4d3f-8c80-a3246bc73bd3">Philippine Statistics Authority</a>']
            })
        }),
        new LayerTile({
            title: '2021 Poverty Incidence Among Population',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_popovertyinc',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                // crossOrigin: 'anonymous',
                attributions: ['<a href="https://openstat.psa.gov.ph/PXWeb/pxweb/en/DB/DB__1E__FY/0031E3DF020.px/?rxid=81880cf7-ddcc-4d3f-8c80-a3246bc73bd3">Philippine Statistics Authority</a>']
            })
        }),
        new LayerTile({
            title: '2023 Poverty Incidence Among Population',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2023_popovertyinc',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                // crossOrigin: 'anonymous',
                attributions: ['<a href="https://openstat.psa.gov.ph/PXWeb/pxweb/en/DB/DB__1E__FY/0031E3DF020.px/?rxid=81880cf7-ddcc-4d3f-8c80-a3246bc73bd3">Philippine Statistics Authority</a>']
            })
        })
        ]
});

export default povertyIncidenceLayers;