import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import SourceOSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import BingMaps from 'ol/source/BingMaps';

const karagatanURL = process.env.GEOSERVER;
const Geoportal = '<a href="https:/www.geoportal.gov.ph">Geoportal Philippines</a>';
const baseMaps = new LayerGroup({
    title: 'Base maps',
    fold: 'close',
    layers: [
        new LayerTile({
            title: 'NAMRIA base map',
            type: 'base',
            visible: true,
            source: new XYZ({
                attributions: [Geoportal],
                url: 'http://basemapserver.geoportal.gov.ph/tiles/v2/PGP/{z}/{x}/{y}.png'
            })
        }),
        new LayerTile({
            title: 'Microsoft BingMaps',
            type: 'base',
            visible: false,
            preload: Infinity,
            source: new BingMaps({
                key: 'AlKK3ON9IooxsERtWw6PLgGnvedYSDP8MkTo0-mfnWqfhf8QY1oOE3Pv2qKVCbCX',
                imagerySet: 'AerialWithLabels',
                maxZoom: 19
            })
        }),
        new LayerTile({
            title: 'Google Map',
            type: 'base',
            visible: false,
            preload: Infinity,
            source: new XYZ({
                attributions: ['© Google <a href="https://developers.google.com/maps/terms">Terms of Use</a>'],
                url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga'

            })
        }),
        new LayerTile({
            title: 'Open Street Map',
            type: 'base',
            visible: false,
            source: new SourceOSM({
                attributions: [
                    'All maps © <a href="https://www.opencyclemap.org/">OpenCycleMap</a>'
                ]
            })
        }),
        new LayerTile({
            title: 'Philippine Relief Model',
            type: 'base',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:Phil_HillshadeBedrock_15arcsec',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [
                    '<a href="https://www.ncei.noaa.gov/products/etopo-global-relief-model">NOAA NCEI. 2022: ETOPO 2022 15 Arc-Second Global Relief Model</a>'
                ]
            })
        })

    ]
});

export default baseMaps;