import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const karagatanURL = process.env.GEOSERVER;
const Geoportal = '<a href="https:/www.geoportal.gov.ph">Geoportal Philippines</a>';

const atinAngKinse = new LayerGroup({
    title: '<a href="https://karagatanpatrol.org/atin_ang_kinse" target="_blank">Atin ang Kinse</a>',
    fold: 'close',
    layers: [
        ///20 fathoms in municipal waters
        new LayerGroup({
            title: 'Municipal Waters Bathymetry',
            fold: 'close',
            layers: [
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/gebco_below36.png" alt="" width="12" height="12"> deeper than 20 fathoms (deeper than ~36m)',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:gebco_fmamunwat_morethan36',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        // crossOrigin: 'anonymous',
                        attributions: ['<a href="https:/www.gebco.net">GEBCO</a>']
                    })
                }),
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/gebco_0_36.png" alt="" width="12" height="12"> 7 - 20 fathoms (13 - ~36m)',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:gebco_fmamunwat_13_36',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        // crossOrigin: 'anonymous',
                        attributions: ['<a href="https:/www.gebco.net">GEBCO</a>']
                    })
                }),
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/gebco_0_13.png" alt="" width="12" height="12"> 0 - 7 fathoms (0 - ~13m)',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:gebco_fmamunwat_0_13',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        // crossOrigin: 'anonymous',
                        attributions: ['<a href="https:/www.gebco.net">GEBCO</a>']
                    })
                })
            ]
        }),
        ////// Coastal Habitats////////////////
        new LayerGroup({
            title: 'Coastal Habitats',
            fold: 'close',
            layers: [
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/corals.png" alt="" width="12" height="12">Coral reefs',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:crm_geoportal_reefs',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        // crossOrigin: 'anonymous',
                        attributions: [Geoportal]
                    })
                }),
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/seaweeds.png" alt="" width="12" height="12">Seagrasses / Seaweeds',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:crm_geoportal_seagrass_seaweeds',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        // crossOrigin: 'anonymous',
                        attributions: [Geoportal]
                    })
                }),
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/mangroves.png" alt="" width="12" height="12">Mangroves',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:crm_geoportal_mangroves',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        // crossOrigin: 'anonymous',
                        attributions: [Geoportal]
                    })
                })
            ]
        })
    ]
});
export default atinAngKinse;