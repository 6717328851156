import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const karagatanURL = process.env.GEOSERVER;
const attEOG = '<a href="https://eogdata.mines.edu/vbd/">Earth Observation Group, Payne Institute for Public Policy</a>';

const viirs_2017 =  new LayerGroup({
    title: '2017',
    fold: 'close',
    layers: [
        new LayerTile({
            title: '2017 January',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2017_January',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2017 February',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2017_February',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2017 March',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2017_March',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2017 April',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2017_April',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2017 May',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2017_May',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2017 June',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2017_June',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2017 July',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2017_July',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2017 August',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2017_August',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2017 September',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2017_September',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2017 October',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2017_October',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2017 November',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2017_November',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2017 December',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2017_December',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        })
            ]
    });

export default viirs_2017;