import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const karagatanURL = process.env.GEOSERVER;
const attEOG = '<a href="https://eogdata.mines.edu/vbd/">Earth Observation Group, Payne Institute for Public Policy</a>';

const viirs_2020 = new LayerGroup({
    title: '2020',
    fold: 'close',
    layers: [
        new LayerTile({
            title: '2020 January',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2020_January',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2020 February',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2020_February',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2020 March',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2020_March',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2020 April',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2020_April',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2020 May',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2020_May',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2020 June',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2020_June',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2020 July',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2020_July',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2020 August',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2020_August',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2020 September',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2020_September',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2020 October',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2020_October',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2020 November',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2020_November',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2020 December',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2020_December',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        })
    ]

    });

export default viirs_2020;