import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const karagatanURL = process.env.GEOSERVER;
const noaaBAA = '<a href="https://coralreefwatch.noaa.gov/product/5km/">NOAA Coral Reef Watch program</a>';

const coralBleaching =        new LayerGroup({
    title: '<a href="https://coralreefwatch.noaa.gov/product/5km/">NOAA Coral Bleaching Alert Area (7-day)</a>',
    fold: 'close',
    layers: [
        ///Alert Watch
        new LayerGroup({
            title : '<img src="https://karagatanpatrol.org/symbols/bleach_watch.png" alt="" width="12" height="12"> Watch',
            fold  : 'close',
            layers : [
                new LayerTile({
                    title: '01 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:01jun2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '08 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:08jun2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '15 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:15jun2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '22 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:22jun2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '29 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:29jun2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '06 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:06jul2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '13 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:13jul2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '20 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20jul2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '27 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:27jul2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '03 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:03aug2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '10 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:10aug2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '17 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:17aug2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '24 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:24aug2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '31 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:31aug2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '07 September 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:07sep2024_BAA_watch',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                })

            ]
        }),
        ///Alert Warning
        new LayerGroup({
            title : '<img src="https://karagatanpatrol.org/symbols/bleach_warning.png" alt="" width="12" height="12"> Warning (Risk of Possible Bleaching)',
            fold : 'close',
            layers: [
                new LayerTile({
                    title: '01 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:01jun2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '08 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:08jun2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '15 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:15jun2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '22 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:22jun2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '29 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:29jun2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '06 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:06jul2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '13 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:13jul2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '20 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20jul2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '27 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:27jul2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '03 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:03aug2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '10 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:10aug2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '17 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:17aug2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '24 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:24aug2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '31 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:31aug2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '07 September 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:07sep2024_BAA_warning',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                })
            ]
        }),
        ///Alert Level 1
        new LayerGroup({
            title : '<img src="https://karagatanpatrol.org/symbols/bleach_level1.png" alt="" width="12" height="12"> Alert Level 1 (Risk of Reef-Wide Bleaching)',
            fold : 'close',
            layers: [
                new LayerTile({
                    title: '01 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:01jun2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '08 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:08jun2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '15 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:15jun2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '22 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:22jun2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '29 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:29jun2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '06 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:06jul2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '13 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:13jul2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '20 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20jul2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '27 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:27jul2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '03 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:03aug2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '10 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:10aug2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '17 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:17aug2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '24 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:24aug2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '31 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:31aug2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '07 September August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:07sep2024_BAA_level1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                })
            ]
        }),
        ///Alert Level 2
        new LayerGroup({
            title : '<img src="https://karagatanpatrol.org/symbols/bleach_level2.png" alt="" width="12" height="12"> Alert Level 2 (Risk of Reef-Wide Bleaching with Mortality of Heat-Sensitive Corals)',
            fold : 'close',
            layers: [
                new LayerTile({
                    title: '01 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:01jun2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '08 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:08jun2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '15 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:15jun2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '22 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:22jun2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '29 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:29jun2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '06 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:06jul2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '13 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:13jul2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '20 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20jul2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '27 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:27jul2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '03 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:03aug2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '10 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:10aug2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '17 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:17aug2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '24 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:24aug2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '31 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:31aug2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '07 September 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:07sep2024_BAA_level2',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                })
            ]
        }),
        ///Alert Level 3
        new LayerGroup({
            title : '<img src="https://karagatanpatrol.org/symbols/bleach_level3.png" alt="" width="12" height="12"> Alert Level 3 (Risk of Multi-Species Mortality)',
            fold : 'close',
            layers: [
                new LayerTile({
                    title: '01 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:01jun2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '08 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:08jun2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '15 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:15jun2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '22 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:22jun2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '29 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:29jun2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '06 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:06jul2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '13 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:13jul2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '20 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20jul2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '27 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:27jul2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '03 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:03aug2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '10 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:10aug2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '17 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:17aug2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '24 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:24aug2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '31 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:31aug2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '07 September 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:07sep2024_BAA_level3',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                })

            ]
        }),
        ///Alert Level 4
        new LayerGroup({
            title : '<img src="https://karagatanpatrol.org/symbols/bleach_level4.png" alt="" width="12" height="12"> Alert Level 4 (Risk of Severe, Multi-Species Mortality (> 50% of corals))',
            fold : 'close',
            layers: [
                new LayerTile({
                    title: '01 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:01jun2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '08 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:08jun2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '15 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:15jun2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '22 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:22jun2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '29 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:29jun2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '06 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:06jul2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '13 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:13jul2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '20 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20jul2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '27 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:27jul2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '03 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:03aug2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '10 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:10aug2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '17 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:17aug2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '24 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:24aug2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '31 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:31aug2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '07 September 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:07sep2024_BAA_level4',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                })
            ]
        }),
        ///Alert Level 5
        new LayerGroup({
            title : '<img src="https://karagatanpatrol.org/symbols/bleach_level5.png" alt="" width="12" height="12"> Alert Level 5 (Risk of Near Complete Mortality (> 80% of corals))',
            fold : 'close',
            layers: [
                new LayerTile({
                    title: '01 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:01jun2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '08 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:08jun2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '15 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:15jun2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '22 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:22jun2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '29 June 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:29jun2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '06 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:06jul2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '13 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:13jul2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '20 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20jul2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '27 July 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:27jul2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '03 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:03aug2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '10 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:10aug2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '17 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:17aug2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '24 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:24aug2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '31 August 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:31aug2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                }),
                new LayerTile({
                    title: '07 September 2024',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:07sep2024_BAA_level5',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [noaaBAA]
                    })
                })
            ]
        }),
        ]
});  
export default coralBleaching;