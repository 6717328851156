import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';


const karagatanURL = process.env.GEOSERVER;
const Geoportal = '<a href="https:/www.geoportal.gov.ph">Geoportal Philippines</a>';

const managementRegimes =  new LayerGroup({
    title: 'Management Regimes',
    fold: 'close',
    layers: [
    new LayerTile({
        title: '<img src="https://karagatanpatrol.org/symbols/nipas.png" alt="" width="12" height="12">National Integrated Protected Areas System',
        visible: true,
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:Protected Areas',
                'VERSION': '1.3.0'
            },
            serverType: 'geoserver'
            // crossOrigin: 'anonymous'
        })
    }),
    new LayerTile({
        title: '<img src="https://karagatanpatrol.org/symbols/fma.png" alt="" width="12" height="12">Fisheries Management Areas',
        visible: true,
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:fma',
                'VERSION': '1.3.0'
            },
            serverType: 'geoserver'
        })
    }),

    new LayerTile({
        title: '<img src="https://karagatanpatrol.org/symbols/ecs.png" alt="" width="12" height="12">Philippine Extended Continental Shelf',
        visible: true,
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:philecs',
                'VERSION': '1.3.0'
            },
            serverType: 'geoserver'
        })
    }),
    new LayerTile({
        title: '<img src="https://karagatanpatrol.org/symbols/internal_wat.png" alt="" width="12" height="12">Philippine Territorial/Internal Waters',
        visible: false,
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:territorialwaters',
                'VERSION': '1.3.0'
            },
            serverType: 'geoserver'
        })
    }),
    new LayerTile({
        title: '<img src="https://karagatanpatrol.org/symbols/munwat.png" alt="" width="12" height="12">Municipal waters-archipelagic principle',
        visible: true,
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:Municipal Waters',
                'VERSION': '1.3.0'
            },
            attributions: [Geoportal],
            serverType: 'geoserver'
        })
    }),
    new LayerTile({
        title: '<img src="https://karagatanpatrol.org/symbols/munwat_mainland.png" alt="" width="12" height="12">Municipal waters-mainland principle(hypothetical)',
        visible: false,
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:munwaters_mainland',
                'VERSION': '1.3.0'
            },
            serverType: 'geoserver'
        })
    }),

    new LayerTile({
        title: '<img src="https://karagatanpatrol.org/symbols/kig.png" alt="" width="12" height="12">Kalayaan Island Group',
        source: new TileWMS({
            url: karagatanURL,
            params: {
                'LAYERS': 'karagatan:kalayaanisgroup',
                'VERSION': '1.3.0'
            },
            serverType: 'geoserver'
        })
    })
]
});

export default managementRegimes;