import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const karagatanURL = process.env.GEOSERVER;

const closedSeason = new LayerGroup({
    title: '<img src="https://karagatanpatrol.org/symbols/closedseason.png" alt="" width="12" height="12"> Closed Season Areas',
    fold: 'close',
    layers: [
        new LayerTile({
            title: 'Zamboanga Peninsula - 15 Nov to 15 Feb (2024)',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:closed_season_zamboanga',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver'
            })
        }),

        new LayerTile({
            title: 'Northern Palawan - 01 Nov to 31 Jan',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:closed_season_palawan',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver'
            })
        }),
        new LayerTile({
            title: 'Visayan Sea - 15 Nov to 15 Feb',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:closed_season_visayas',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver'
            })
        })
        // new LayerTile({
        //     title: 'Balayan Bay - 17 Nov 2019 to 17 Jan 2020',
        //     visible: false,
        //     source: new TileWMS({
        //         url: karagatanURL,
        //         params: {
        //             'LAYERS': 'karagatan:BalayanClosedSeason',
        //             'VERSION': '1.3.0'
        //         },
        //         serverType: 'geoserver'
        //     })
        // })

    ]
});
export default closedSeason;