import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const karagatanURL = process.env.GEOSERVER;
const attEOG = '<a href="https://eogdata.mines.edu/vbd/">Earth Observation Group, Payne Institute for Public Policy</a>';

const viirs_2022 =  new LayerGroup({
    title: '2022',
    fold: 'close',
    layers: [
        new LayerTile({
            title: '2022 January',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2022_January',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2022 February',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2022_February',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2022 March',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2022_March',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2022 April',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2022_April',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2022 May',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2022_May',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2022 June',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2022_June',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2022 July',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2022_July',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2022 August (NOAA-20)',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2022_August',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2022 September',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2022_September',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2022 October',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2022_October',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2022 November',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2022_November',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2022 December',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2022_December',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        })
    ]
         
    });

export default viirs_2022;