import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const karagatanURL = process.env.GEOSERVER;
const attEOG = '<a href="https://eogdata.mines.edu/vbd/">Earth Observation Group, Payne Institute for Public Policy</a>';

const viirs_2021 =  new LayerGroup({
    title: '2021',
    fold: 'close',
    layers: [
        new LayerTile({
            title: '2021 January',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_January',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2021 February',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_February',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2021 March',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_March',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2021 April',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_April',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2021 May',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_May',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2021 June',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_June',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2021 July',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_July',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2021 August',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_August',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2021 September',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_September',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2021 October',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_October',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2021 November',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_November',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2021 December',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2021_December',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        })
    ]
         
 
    });

export default viirs_2021;