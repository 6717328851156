import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const karagatanURL = process.env.GEOSERVER;

const lawEnforcement = new LayerGroup({
    title: 'Law Enforcement Agencies',
    fold: 'close',
    layers: [
        new LayerTile({
            title: '<img src="https://karagatanpatrol.org/symbols/denr.png" alt="" width="16" height="16"> Department of Environment and Natural Resources (DENR)',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:DENR',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver'
            })
        }),
        new LayerTile({
            title: '<img src="https://karagatanpatrol.org/symbols/Marina.png" alt="" width="16" height="16"> Maritime Industry Authority (MARINA)',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:MARINA',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver'
            })
        }),
        new LayerTile({
            title: '<img src="https://karagatanpatrol.org/symbols/pnpmaritime.png" alt="" width="16" height="16"> PNP Maritime Group (PNP-MG)',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:pnp_mg',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver'
            })
        }),
        new LayerTile({
            title: '<img src="https://karagatanpatrol.org/symbols/pcg.png" alt="" width="16" height="16"> Philippine Coast Guard (PCG)',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:PCG',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver'
            })
        }),
        new LayerTile({
            title: '<img src="https://karagatanpatrol.org/symbols/bfar.png" alt="" width="16" height="16"> Bureau of Fisheries and Aquatic Resources (BFAR)',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:bfar',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver'
            })
        }),
        new LayerTile({
            title: '<img src="https://karagatanpatrol.org/symbols/pnp.png" alt="" width="16" height="16"> Philippine National Police (PNP)',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:pnp',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver'
            })
        })
    ]
});
export default lawEnforcement;