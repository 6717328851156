import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const karagatanURL = process.env.GEOSERVER;
const attEOG = '<a href="https://eogdata.mines.edu/vbd/">Earth Observation Group, Payne Institute for Public Policy</a>';

const viirs_2018 =  new LayerGroup({
    title: '2018',
    fold: 'close',
    layers: [
        new LayerTile({
            title: '2018 January',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_January',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2018 February',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_February',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2018 March',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_March',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2018 April',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_April',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2018 May',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_May',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2018 June',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_June',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2018 July',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_July',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2018 August',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_August',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2018 September',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_September',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2018 October',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_October',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2018 November',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_November',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        }),
        new LayerTile({
            title: '2018 December',
            visible: false,
            source: new TileWMS({
                url: karagatanURL,
                params: {
                    'LAYERS': 'karagatan:2018_December',
                    'VERSION': '1.3.0'
                },
                serverType: 'geoserver',
                attributions: [attEOG]
            })
        })
        ]
            
    });

export default viirs_2018;