import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

const karagatanURL = process.env.GEOSERVER;
const PhilSA = '<a href="https://philsa.gov.ph/">Philippine Space Agency</a>';
const Copernicus = '<a href="https://rapidmapping.emergency.copernicus.eu/EMSR741/">Copernicus Emergency Management System </a>';

const oilSpill = new LayerGroup({
    title: 'Oil Spill Monitor',
    fold: 'close',
    layers: [
        new LayerGroup({
            title: '<img src="https://karagatanpatrol.org/symbols/oilspillmon.png" alt="" width="12" height="12">Copernicus Emergency Management Service (EMS)',
            fold: 'close',
            layers: [
                new LayerTile({
                    title: '<a href="https://rapidmapping.emergency.copernicus.eu/EMSR741/"> 26 July 2024 (RCM)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:emsr741_v2_07262024_rcm',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [Copernicus]
                    }),          
                }),
                new LayerTile({
                    title: '<a href="https://rapidmapping.emergency.copernicus.eu/EMSR741/"> 26 July 2024 (PAZ)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:emsr741_v2_07262024_paz',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [Copernicus]
                    }),          
                }),
                new LayerTile({
                    title: '<a href="https://rapidmapping.emergency.copernicus.eu/EMSR741/"> 27 July 2024 (TerraSAR-X)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:emsr741_v2_07272024_terx',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [Copernicus]
                    })
                }),
                new LayerTile({
                    title: '<a href="https://rapidmapping.emergency.copernicus.eu/EMSR741/"> 28 July 2024 (Sentinel 1)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:emsr741_v2_07282024_s1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [Copernicus]
                    })
                }),
                new LayerTile({
                    title: '<a href="https://rapidmapping.emergency.copernicus.eu/EMSR741/"> 30 July 2024 (Sentinel 1)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:emsr741_v2_07302024_s1',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [Copernicus]
                    })
                }),
            ]
        }),
        new LayerGroup({
            title: '<img src="https://karagatanpatrol.org/symbols/oilspillmon.png" alt="" width="12" height="12">Philippine Space Agency Oil Spill Monitor',
            fold: 'close',
            layers: [
                new LayerTile({
                    title: '<a href="https://www.facebook.com/share/p/qcDS85GD9ptX1fmr/"> 26 July 2024 (RCM)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20240726_terranova',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [PhilSA]
                    })
                }),
                new LayerTile({
                    title: '<a href="https://www.facebook.com/share/p/uBy3WSry9ZWzurCE/"> 27 July 2024 (RCM)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20240727_terranova',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [PhilSA]
                    })
                }),
                new LayerTile({
                    title: '<a href="https://www.facebook.com/share/p/TJybUXEx2xemRZcF/"> 27 July 2024 (GeoEye)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20240727_geoeye_oilspill',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [PhilSA]
                    })
                }),
               
                new LayerTile({
                    title: '<a href="https://www.facebook.com/share/p/X4poPrFkLEs9z3tv/"> 27 July 2024 (Tandem-X)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20240727_tandex_oilspill',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [PhilSA]
                    })
                }),
                new LayerTile({
                    title: '<a href="https://www.facebook.com/share/p/p8cpao6bNS9gEH6F/"> 28 July 2024 (Sentinel 1)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20240728_s1_oilspill',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [PhilSA]
                    })
                }),                        
                new LayerTile({
                    title: '<a href="https://www.facebook.com/share/p/8NhS43bDGXnmK145/"> 28 July 2024 (RCM)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20240728_rcm_revised',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [PhilSA]
                    })
                }),
                new LayerTile({
                    title: '<a href="https://www.facebook.com/share/p/TJybUXEx2xemRZcF/"> 29 July 2024 (EOS-4)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20240729_eos4_oilspill',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [PhilSA]
                    })
                }),
                new LayerTile({
                    title: '<a href="https://www.facebook.com/share/p/p8cpao6bNS9gEH6F/"> 30 July 2024 (Sentinel 1)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:20240730_s1_oilspill_merged_kp',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [PhilSA]
                    })
                })
                
            ]
        }),
        new LayerGroup ({
            title: '<img src="https://karagatanpatrol.org/symbols/oilsightings.png" alt="" width="10" height="12">  <a href="https://docs.google.com/spreadsheets/d/14S02vQiSEAK0MrX_jRC8EkejZwNhtS4lUhDHW93XRlU/edit?usp=sharing"> Oil Sightings</a>',
            fold: 'close',
            layers: [
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/oilsightings.png" alt="" width="10" height="12">30 July 2024</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:oilsightings_20240730',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                    })
                }),
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/oilsightings.png" alt="" width="10" height="12">29 July 2024</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:oilsightings_20240729',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                    })
                }),
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/oilsightings.png" alt="" width="10" height="12">28 July 2024</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:oilsightings_20240728',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                    })
                })
                
            ]
        }),
        new LayerGroup ({
            title: '<img src="https://karagatanpatrol.org/symbols/oil_spill1.png" alt="" width="10" height="12">Point Source Location',
            fold: 'close',
            layers: [
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/oil_spill1.png" alt="" width="10" height="12"><a href="https://www.facebook.com/share/p/qcDS85GD9ptX1fmr/">MT Terranova Location (July 25, 2024)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:mtterranova_oilspill',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        attributions: [PhilSA]
                    })
                }),
                new LayerTile({
                    title: '<img src="https://karagatanpatrol.org/symbols/oil_spill1.png" alt="" width="10" height="12">MTKR Jason Bradley (July 27, 2024 approx. locaton)</a>',
                    visible: false,
                    source: new TileWMS({
                        url: karagatanURL,
                        params: {
                            'LAYERS': 'karagatan:mtkrjasonbradley_location_pt',
                            'VERSION': '1.3.0'
                        },
                        serverType: 'geoserver',
                        // attributions: [PhilSA]
                    })
                }),
            ]
        })
                        ]
});

export default oilSpill;